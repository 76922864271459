import { WidthContainer } from '@pata-app/core';

import React from 'react';

import SectionContainer from '../../../../components/sectionContainer/sectionContainer';
import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import TopFeatureTile from './topFeatureTile';
import { TOP_FEATURES_TITLES_CONFIG } from './topFeatures.config';
import { TilesContainer } from './topFeatures.styled';

const TopFeatureTitle = () => {
  return (
    <TextWithHighlight>
      <h2 style={{ textAlign: 'center' }}>
        Here is
        <span>
          {' '}
          what Pata <br /> can offer{' '}
        </span>
        to pet groomers
      </h2>
    </TextWithHighlight>
  );
};

export function TopFeatures() {
  return (
    <WidthContainer noBackground>
      <SectionContainer title={TopFeatureTitle}>
        <TilesContainer>
          {TOP_FEATURES_TITLES_CONFIG.map((tile, index) => (
            <TopFeatureTile
              alignment={index % 2 === 0 ? 'left' : 'right'}
              action={tile.action}
              title={tile.title}
              description={tile.description}
              imageSrc={tile.image}
            />
          ))}
        </TilesContainer>
      </SectionContainer>
    </WidthContainer>
  );
}
