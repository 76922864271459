import { Image } from '@pata-app/core';
import { WidthContainer } from '@pata-app/ui-document';
import { Button } from '@pata-app/ui-form';

import React from 'react';

import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { businessBenefits } from './businessBenefits.config';
import {
  ActionContainer,
  BusinessBenefit,
  BusinessBenefitsContainer,
  BusinessBenefitsContent,
  UnlimitedContainer,
  UnlimitedLabel,
} from './businessBenefits.styled';

const BusinessBenefits = () => {
  const handleSignUpClick = () => {
    window.location.href = `${process.env.PARTNERS_BASE_URL}/sign-up`;
  };

  const handleBookDemo = () => {
    window.location.href = `https://meetings.hubspot.com/pata/demo`;
  };

  return (
    <BusinessBenefitsContainer>
      <WidthContainer noBackground>
        <BusinessBenefitsContent>
          <TextWithHighlight>
            <h2>
              Forget complicated subscriptions
              <br />
              and hidden charges:{' '}
              <span>
                Pata is a <br /> subscription-free platform
              </span>
            </h2>
          </TextWithHighlight>

          <UnlimitedContainer>
            <UnlimitedLabel>
              <strong>Unlimited</strong>
            </UnlimitedLabel>
            <ul>
              {businessBenefits.map((benefit) => (
                <BusinessBenefit key={benefit.title}>
                  <strong>{benefit.title}</strong>
                  <p>{benefit.description}</p>
                </BusinessBenefit>
              ))}
            </ul>
          </UnlimitedContainer>

          <ActionContainer>
            <Button onClick={handleSignUpClick}>Sign up today</Button>
            <Button buttonType="secondary" onClick={handleBookDemo}>
              Book a demo
            </Button>
          </ActionContainer>
        </BusinessBenefitsContent>
        <Image
          src={`${process.env.CDN_BASE_URL}/images/image-dog-with-tongue-out.webp`}
          alt="Happy dog with tongue hanging out"
          height={1500}
          width={2500}
        />
      </WidthContainer>
    </BusinessBenefitsContainer>
  );
};

export default BusinessBenefits;
