import styled from 'styled-components';

export const TilesContainer = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 32px;

  margin-top: 60px;

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    column-gap: 160px;
    row-gap: 0;

    width: 100%;
    max-width: 1000px;

    margin: auto;
    margin-top: 60px;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.Xl}`} {
    width: 60%;
  } ;
`;
