import { WidthContainer } from '@pata-app/core';

import React from 'react';

import Icon from '../../../../components/icon/icon';
import SectionContainer from '../../../../components/sectionContainer/sectionContainer';
import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { TestimoninalContent, VatExclusion } from './testimoninal.styled';
import { TestimoninalTile } from './testimoninalTile/testimoninalTile';
import {
  FeatureHighlight,
  FeatureTileContent,
  FeatureTilesContainer,
  MessageCount,
  PercentageContainer,
} from './testimoninalTile/testimoninalTile.styled';

const TopFeatureTitle = () => {
  return null;
};

export function Testimoninal() {
  return (
    <WidthContainer noBackground>
      <SectionContainer title={TopFeatureTitle}>
        <FeatureTilesContainer>
          <TestimoninalTile alignment="right" image={`${process.env.CDN_BASE_URL}/images/image-person-on-iphone.jpeg`}>
            <TestimoninalContent>
              <p>
                "Text about how I loved Pata a lot Text about how I loved Pata a lot Text about how I loved Pata a lot
                Text about how I loved Pata a lot Text about how I loved Pata a lot Pata!"
              </p>

              <p>John Smith - Best in Grooming</p>
            </TestimoninalContent>
          </TestimoninalTile>
        </FeatureTilesContainer>
      </SectionContainer>
    </WidthContainer>
  );
}
