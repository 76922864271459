import { Image } from '@pata-app/core';
import { Button } from '@pata-app/ui-form';

import React from 'react';

import { TopFeatureTileContainer } from './topFeatureTile.styled';

interface TopFeatureTileProps {
  title: string;
  description: string;
  imageSrc: string;
  action: {
    onClick: (event: React.MouseEvent) => void;
    actionText: string;
    buttonType: 'primary' | 'secondary';
  };
  alignment: 'left' | 'right';
}

export default function TopFeatureTile({
  title,
  description,
  imageSrc,
  action,
  alignment = 'left',
}: TopFeatureTileProps) {
  return (
    <TopFeatureTileContainer $alignment={alignment}>
      <Image src={imageSrc} />
      <h3>{title}</h3>
      <p>{description}</p>
      {Boolean(action) && (
        <Button buttonType={action.buttonType} onClick={action.onClick}>
          {action.actionText}
        </Button>
      )}
    </TopFeatureTileContainer>
  );
}
