import { Image, WidthContainer } from '@pata-app/core';
import { Button } from '@pata-app/ui-form';

import React from 'react';

import Icon from '../../../../components/icon/icon';
import SectionContainer from '../../../../components/sectionContainer/sectionContainer';
import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { ActionContainer } from '../heroSection/heroSection.styled';
import { SIGN_UP_PROCESS_CONFIG } from './signUpProcess.config';
import {
  ProcessesContainer,
  ProcessDot,
  ProcessItem,
  SignUpProcessContainer,
  SignUpProcessContent,
  SignUpProcessTitleContainer,
  ProcessContainer,
} from './signUpProcess.styled';

const SignUpProcessTitle = () => {
  return (
    <SignUpProcessTitleContainer>
      <h2>
        Join the top UK pet groomers enjoying
        <br />a free and easy-to-use booking <br /> management platform.
      </h2>
    </SignUpProcessTitleContainer>
  );
};

export function SignUpProcess() {
  const handleSignUpClick = () => {
    window.location.href = `${process.env.PARTNERS_BASE_URL}/sign-up`;
  };

  const handleBookDemo = () => {
    window.location.href = `https://meetings.hubspot.com/pata/demo`;
  };
  // const theme = useTheme();
  return (
    <SignUpProcessContainer>
      <Image
        src={`${process.env.CDN_BASE_URL}/images/image-phone-laptop-app.webp`}
        alt="Pata Partners app on laptop and iPhone"
        height={2000}
        width={2000}
      />
      <WidthContainer noBackground>
        <SectionContainer title={SignUpProcessTitle}>
          <SignUpProcessContent>
            <ProcessesContainer>
              <ActionContainer>
                <Button onClick={handleSignUpClick}>Sign up today</Button>
                <Button buttonType="secondary" onClick={handleBookDemo}>
                  Book a demo
                </Button>
              </ActionContainer>
            </ProcessesContainer>
          </SignUpProcessContent>
        </SectionContainer>
      </WidthContainer>
    </SignUpProcessContainer>
  );
}
