import styled from 'styled-components';

export const BusinessBenefitsContainer = styled.section`
  display: grid;

  background: ${({ theme }) => theme.core.colors.primary.blue.medium};

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  img {
    width: 80%;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    img {
      width: 60%;
    }

    button {
      cursor: pointer;
      min-width: 150px;
    }
  }
  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 30px auto;

    overflow: hidden;
    > div {
      display: flex;
      align-items: normal;
      flex-direction: row;
      justify-content: center;
      grid-column: 1 / span 4;
      grid-row: 2 / span 1;
    }

    img {
      object-fit: cover;

      max-height: 600px;
      width: 30%;

      overflow: visible;
    }
  }
`;

export const BusinessBenefitsContent = styled.div`
  height: min-content;

  align-self: center;

  margin: ${({ theme }) => theme.core.sizing[2]}px ${({ theme }) => theme.core.sizing[4]}px;
  color: ${({ theme }) => theme.colors.greyscale.white};
  /* padding: ${({ theme }) => theme.core.sizing[2]}px; */
  padding-top: 70px;

  h2 {
    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;

    margin: 0;
    > span {
      font-family: ${({ theme }) => theme.core.fontFamily.jost};

      &:last-of-type {
        span {
          color: ${({ theme }) => theme.core.colors.primary.green.medium};
        }
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.core.sizing[2]}px;

    padding: 0;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    padding: ${({ theme }) => theme.core.sizing[4]}px ${({ theme }) => theme.core.sizing[4]}px 64px 64px;

    margin: 16px 0;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    margin: 0;
    margin-top: initial;
  }
`;

export const BusinessBenefit = styled.li`
  list-style: none;

  strong {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    font-family: ${({ theme }) => theme.core.fontFamily.openSans};
    font-size: 16px;
    font-weight: 600;
  }
  p {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    margin: 0;
    font-family: ${({ theme }) => theme.core.fontFamily.openSans};
    font-size: ${({ theme }) => theme.core.fontSizes.m}px;
    font-weight: 300;
  }

  svg {
    flex: 0 0 auto;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-self: flex-start;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  padding-left: 64px;

  button {
    border: none;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    /* padding-left: 110px; */
  }
`;

export const UnlimitedContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.core.sizing[4]}px;

  ul {
    padding-left: 65px;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    ul {
      /* padding-left: 110px; */
    }
  }
`;

export const UnlimitedLabel = styled.div`
  position: relative;
  strong {
    position: absolute;
    top: 95px;
    left: -85px;
    transform: rotate(-90deg);
    font-size: 45px;

    display: inline-block;
    transform-origin: center;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    strong {
      /* left: -30px; */
    }
  }
`;
