import styled from 'styled-components';

interface FeatureTileContainerProps {
  $alignment: 'left' | 'right;';
}

export const FeatureTilesContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  width: 100%;

  gap: ${({ theme }) => theme.core.sizing[5]}px;

  margin: 100px auto;

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    grid-template-columns: 1fr;
  }
`;

export const FeatureTileContainer = styled.div<FeatureTileContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[3]}px;
  width: 100%;
  height: 100%;

  border: 0.5px solid ${({ theme }) => theme.core.colors.greyscale.dark};
  border-right: 10px solid ${({ theme }) => theme.core.colors.secondary.yellow.medium};
  border-radius: ${({ theme }) => theme.core.border.container.radius}px;

  > svg {
    height: 45px;
    width: 45px;

    fill: ${({ theme }) => theme.core.colors.primary.green.medium};
  }

  img {
    max-width: 100%;
    height: 250px;
  }

  margin: auto;

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    flex-direction: ${({ $alignment }) => ($alignment === 'left' ? 'row' : 'row-reverse')};
    max-width: 1000px;

    border-top: 0.5px solid ${({ theme }) => theme.core.colors.primary.green.medium};
    /* border-${({ $alignment }) => $alignment}: 8px solid ${({ theme }) => theme.core.colors.primary.green.medium}; */

    img {
      width: 30%;
      min-width: 300px;
      object-fit: cover;
    }

    > svg {
      width: 45px;
      flex-shrink: 0;

      fill: ${({ theme }) => theme.core.colors.primary.green.medium};
    }
  }
  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
  }
`;

export const FeatureTileContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[0]}px;

  strong {
    font-size: ${({ theme }) => theme.core.fontSizes.l}px;
  }

  h3 {
    color: ${({ theme }) => theme.core.colors.primary.green.medium};

    margin: 0;

    font-size: ${({ theme }) => theme.core.fontSizes.xl}px;
  }

  p {
    margin: 0;

    &:first-of-type {
      margin-top: ${({ theme }) => theme.core.sizing[1]}px;
    }
  }
`;

export const FeatureHighlight = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  > div:first-of-type {
    width: min-content;
  }
  > div:last-of-type {
    display: flex;
    flex-direction: column;
  }
`;

export const MessageCount = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[0]}px;

  span {
    font-weight: ${({ theme }) => theme.core.fontWeight.semiBold};

    &:first-of-type {
      font-size: ${({ theme }) => theme.core.fontSizes.xl}px;
    }
    &:last-of-type {
      font-size: ${({ theme }) => theme.core.fontSizes.l}px;
    }
  }
`;

export const PercentageContainer = styled.div`
  font-weight: ${({ theme }) => theme.core.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;
`;
