import styled from 'styled-components';

export const TestimoninalContent = styled.div`
  padding: 0 ${({ theme }) => theme.core.sizing[5]}px;

  p {
    &:first-of-type {
      font-size: ${({ theme }) => theme.core.fontSizes.xxl}px;
      font-weight: ${({ theme }) => theme.core.fontWeight.semiBold};
      font-family: ${({ theme }) => theme.core.fontFamily.jost};
    }
    &:last-of-type {
      color: ${({ theme }) => theme.core.colors.greyscale.dark};
    }
  }
`;
