import styled from 'styled-components';

export const SignUpProcessContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;

  overflow: hidden;

  background: ${({ theme }) => theme.core.colors.secondary.yellow.medium};

  > div {
    grid-column: 1 / span 5;
    grid-row: 1 / span 1;

    > section {
      margin-bottom: 0;
    }
  }

  img {
    width: 100%;
    grid-column: 1 / span 5;
    margin-bottom: 50px;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    img {
      display: flex;

      grid-column: 4 / span 3;
      grid-row: 1 / span 1;

      width: 100%;
      height: 100%;

      margin-bottom: 0px;

      object-fit: cover;
      overflow: visible;
    }

    > div {
      margin: auto;

      > section {
        margin-bottom: initial;
      }
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.xl}`} {
    img {
      display: flex;

      grid-column: 3 / span 3;
      grid-row: 1 / span 1;

      width: 100%;

      height: 100%;

      object-fit: cover;
    }
  }
`;

export const SignUpProcessTitleContainer = styled.div`
  h2,
  p {
    color: ${({ theme }) => theme.core.colors.greyscale.white};
  }

  p {
    margin: ${({ theme }) => theme.core.sizing[4]}px 0 ${({ theme }) => theme.core.sizing[2]}px 0;
  }
`;

export const SignUpProcessContent = styled.div`
  margin-top: ${({ theme }) => theme.core.sizing[5]}px;

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    margin-bottom: 70px;
  }
`;

export const ProcessesContainer = styled.ul`
  display: flex;
  flex-direction: column;

  margin: 0;

  padding: 0;

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    width: 50%;
  }
`;

export const ProcessContainer = styled.div`
  display: grid;
  column-gap: ${({ theme }) => theme.core.sizing[3]}px;
  grid-template-columns: 30px auto;
  grid-template-rows: auto auto;
  align-items: flex-start;

  color: ${({ theme }) => theme.core.colors.greyscale.white};

  strong {
    &:first-of-type {
      align-self: flex-start;
      grid-row: 1 / span 2;

      font-size: 40px;
    }
    &:last-of-type {
      font-size: ${({ theme }) => theme.core.fontSizes.xl}px;
    }
  }

  p {
    width: 80%;
  }
`;

export const ProcessItem = styled.li`
  position: relative;

  display: flex;
  gap: ${({ theme }) => theme.core.sizing[2]}px;
  margin: 0;
  padding: 0;
  padding-bottom: ${({ theme }) => theme.core.sizing[3]}px;

  list-style: none;

  &:last-of-type {
    padding-bottom: 0;

    &:before {
      display: none;
    }
  }

  p {
    margin: 0;
  }
`;

export const ProcessDot = styled.div`
  flex-shrink: 0;

  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.core.colors.greyscale.white};
  border: 2px solid ${({ theme }) => theme.core.colors.primary.green.medium};
  border-radius: 50%;
  position: relative;
`;
