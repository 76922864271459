import styled from 'styled-components';

export const TopFeatureTileContainer = styled.div`
  img {
    object-fit: cover;

    width: 100%;

    border-radius: ${({ theme }) => theme.core.border.container.radius}px;
  }
  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    display: grid;
    grid-template-columns: ${({ $alignment }) => ($alignment === 'left' ? 'min-content auto' : 'auto min-content')};
    grid-template-rows: 1fr min-content min-content min-content 1fr;

    grid-column-gap: 30px;
    grid-row-gap: ${({ theme }) => theme.core.sizing[1]}px;

    * {
      margin: 0;

      max-width: 450px;
    }

    h3,
    p,
    button {
      grid-column: ${({ $alignment }) => ($alignment === 'left' ? '2 / span 1' : '1 / span 1')};
      justify-self: ${({ $alignment }) => ($alignment === 'left' ? 'flex-start' : 'flex-end')};
    }

    h3 {
      grid-row: 2 / span 1;

      text-align: ${({ $alignment }) => ($alignment === 'left' ? 'left' : 'right')};

      color: ${({ theme }) => theme.core.colors.primary.green.medium};
      font-size: ${({ theme }) => theme.core.fontSizes.xl}px;
    }

    p {
      grid-row: 3 / span 1;
      text-align: ${({ $alignment }) => ($alignment === 'left' ? 'left' : 'right')};
    }

    button {
      grid-row: 4 / span 1;

      width: 150px;
    }

    img {
      grid-column: ${({ $alignment }) => ($alignment === 'left' ? '1 / span 1' : '2 / span 1')};
      grid-row: 1 / span 5;

      object-fit: cover;

      border-radius: ${({ theme }) => theme.core.border.container.radius}px;
      height: 300px;
      width: 250px;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    grid-template-columns: ${({ $alignment }) => ($alignment === 'left' ? 'min-content auto' : 'auto min-content')};
  }
`;
